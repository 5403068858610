import React from 'react';
import './Privacy.css';

export default function Privacy() {
  return (
    <div className='privacy'>
      <h1>Privacy Policy</h1>
      <p>This policy applies to all information collected or submitted on Golfer Royale’s website and our apps for iPhone and any other devices and platforms.</p>
      <h2>Information we collect</h2>
      <p>
        {
          `When creating an account, you will be 
          asked to enter a username, an email address 
          and a password. Email addresses are used for 
          logging in, password resets, responding to 
          emails that you initiate, and notifications 
          that you request.\n\n`
        }
      </p>
      <h3>Technical basics</h3>
      <p>
        {
          `If you upload files to Golfer Royale, we need 
          to store them until you delete them.\n\n
          We use cookies on the site and tokens 
          in the app to keep you logged in. 
          Our server software may also store basic 
          technical information, such as your IP
          address, in temporary memory or logs.`
        }
      </p>
      <h3>Analytics</h3>
      <p>
        {
          `Golfer Royale’s app collects aggregate statistics, 
          such as the percentage of users who use particular 
          features in order to improve the app.`
        }
      </p>
      <h2>Information usage</h2>
      <p>
        {
          `We use the information we collect to operate and 
          improve our website, apps, and customer support.\n\n
          We do not share personal information with outside 
          parties. We may share user published data with other 
          users at the community level, like when a user submits 
          a golf round it will be available to other users to see.\n\n
          We may disclose your information in response to 
          subpoenas, court orders, or other legal requirements; 
          to exercise our legal rights or defend against legal 
          claims; to investigate, prevent, or take action 
          regarding illegal activities, suspected fraud or 
          abuse, violations of our policies; or to protect our 
          rights and property.\n\n
          In the future, we may sell to, buy, merge with, or 
          partner with other businesses. In such transactions, 
          user information may be among the transferred assets.\n\n`
        }
      </p>
      <h2>Security</h2>
      <p>
        {
          `We implement a variety of security measures to help keep 
          your information secure. For instance, all communication 
          with the app and website requires HTTPS with certificate 
          pinning. Passwords are hashed, not stored, using 
          industry-standard methods.`
        }
      </p>
      <h2>Deleting your information</h2>
      <p>
        {
          `You may delete your account from the Golfer Royale iOS 
          app.\n\n
          Deleted information may be kept in backups for up to 
          90 days. Backups are encrypted and are only accessed 
          if needed for disaster recovery.`
        }
      </p>
      <h2>California Online Privacy Protection Act Compliance</h2>
      <p>
        {
          `We comply with the California Online Privacy Protection 
          Act. We therefore will not distribute your personal 
          information to outside parties without your consent.`
        }
      </p>
      <h2>Children’s Online Privacy Protection Act Compliance</h2>
      <p>
        {
          `We never collect or maintain information at our website 
          from those we know are under 13, and no part of 
          our website is structured to attract anyone under 13.`
        }
      </p>
      <h2>Information for European Union Customers</h2>
      <p>
        {
          `By using Golfer Royale and providing your information, 
          you authorize us to collect, use, and store your 
          information outside of the European Union.`
        }
      </p>
      <h2>International Transfers of Information</h2>
      <p>
        {
          `Information may be processed, stored, and used outside 
          of the country in which you are located. Data privacy 
          laws vary across jurisdictions, and different laws may 
          be applicable to your data depending on where it is 
          processed, stored, or used.`
        }
      </p>
      <h2>Your Consent</h2>
      <p>
        {
          'By using our site or apps, you consent to our privacy policy.'
        }
      </p>
      <h2>Contacting Us</h2>
      <p>
        {
          `If you have questions regarding this privacy policy, you may 
          email support@chips.golf.`
        }
      </p>
      <h2>Changes to this policy</h2>
      <p>
        {
          `If we decide to change our privacy policy, we will post those 
          changes on this page. Summary of changes so far:`
        }
      </p>
      <ul>
        <li>December 01, 2023: First published.</li>
      </ul>
    </div>
  );
}