import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { GlobalStateProvider } from './context/GlobalState';

import Home from './screens/Home';
import Buy from './screens/Buy';
import Terms from './screens/Terms';
import Privacy from './screens/Privacy';
import Rules from './screens/Rules';
import Contact from './screens/Contact';
import Success from './screens/Success';
import Forgot from './screens/Forgot';
// import Footer from './components/Footer';

import Header from './components/Header';

import './App.css';

function App() {
  return (
        <GlobalStateProvider>
          <div className="App">
            <Router>
              <Header isMobile={isMobile} />
              <Routes>
                <Route path="/" element={<Home isMobile={isMobile} />} />
                <Route path="/buy" element={<Buy isMobile={isMobile} />} />
                <Route path="/success" element={<Success isMobile={isMobile} />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/rules" element={<Rules />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/forgot" element={<Forgot />} />
              </Routes>
              {/* <Footer isMobile={isMobile} /> */}
            </Router>
          </div>
        </GlobalStateProvider>    
  );
}

export default App;
