import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const scorecard = require('../GR_Website_1.png');
const history = require('../GR_Website_2.png');
const log = require('../GR_Website_3.png');
const publicEvents = require('../GR_Website_enter.png');
const leaderboard = require('../GR_Website_5.png');
const privateEvents = require('../GR_Website_create.png');
// const logo = require('../logo_no_text.png');
const logo = require('../cincinnati_royale.png');
const apple = require('../apple.png');
const android = require('../android.png');

const appleLink = 'https://apps.apple.com/us/app/golfer-royale/id6467991220';
const androidLink = 'https://play.google.com/store/apps/details?id=xyz.chipsgolf.chips';

const SUBSTACK = 'https://golferroyale.substack.com/';
const IG = 'https://www.instagram.com/golferroyale/';
const TIKTOK = 'https://www.tiktok.com/@golfer.royale';


export default function Home({isMobile}) {
  const navigate = useNavigate();

  const containerStyle = isMobile ? 'mobile-container' : 'container';
  const screenshotStyle = isMobile ? 'screenshot-mobile' : 'screenshot';


  const goTo = (path) => navigate(`/${path}/`);

  return (
    <div className={containerStyle}>
      <span className={{flexDirection: 'row'}}>
        <span className={isMobile ? "main-title-mobile" : "main-title"}>GOLFER ROYALE</span>
        <span className={isMobile ? "subtitle-mobile" : "subtitle"}> PRESENTS</span>
      </span>
      {/* <p className={isMobile ? "main-title-mobile" : "main-title"}>GOLFER ROYALE</p> */}
      {/* <p className={isMobile ? "subtitle-mobile" : "subtitle"}>AMATEUR GOLF TOURNAMENTS FOR EVERYONE</p> */}
      <img
        src={logo}
        alt="logo"
        className={isMobile ? "logo-mobile" : "logo"}
      />
      <div className={isMobile ? "download-row-mobile" : "download-row"}>
        <a href={appleLink} target="_blank" rel="noopener noreferrer">
          <img
            src={apple}
            alt="apple"
            className={isMobile ? "download-button-mobile" : "download-button"}
          />
        </a>
        <a href={androidLink} target="_blank" rel="noopener noreferrer">
          <img
            src={android}
            alt="android"
            className={isMobile ? "download-button-mobile" : "download-button"}
          />
        </a>
      </div>
      {
        isMobile 
        ?
        <div className='column'>
          <img
            src={publicEvents}
            alt="events"
            className={screenshotStyle}
          />  
          <img
            src={privateEvents}
            alt="stats"
            className={screenshotStyle}
          />
          <img
            src={leaderboard}
            alt="leaderboard"
            className={screenshotStyle}
          />
          <img
            src={log}
            alt="hole_detail"
            className={screenshotStyle}
          />
          <img
            src={scorecard}
            alt="scorecard"
            className={screenshotStyle}
          />
          <img
            src={history}
            alt="round_history"
            className={screenshotStyle}
          />
        </div>
        :
        <div>
          <div className='row'>
            <img
              src={publicEvents}
              alt="events"
              className={screenshotStyle}
            />  
            <img
              src={privateEvents}
              alt="stats"
              className={screenshotStyle}
            />
            <img
              src={leaderboard}
              alt="leaderboard"
              className={screenshotStyle}
            />
          </div>
          <div className="row">
            <img
              src={log}
              alt="hole_detail"
              className={screenshotStyle}
            />
            <img
              src={scorecard}
              alt="scorecard"
              className={screenshotStyle}
            />
            <img
              src={history}
              alt="round_history"
              className={screenshotStyle}
            />

          </div>
        </div>
      }

      <div className="footer-row">
        <div className="footer-column-left">
          <p className="footer-header-left">ABOUT</p>
          <p className="footer-text-left" onClick={() => goTo('rules')}>Rules</p>
          <p className="footer-text-left" onClick={() => goTo('contact')}>Contact</p>
          <p className="footer-text-left" onClick={() => goTo('terms')}>Terms</p>
          <p className="footer-text-left" onClick={() => goTo('privacy')}>Privacy</p>
        </div>
        <div className="footer-column-right">
          <p className="footer-header-left">SOCIAL</p>
          <p className="footer-text-right" onClick={() => window.open(SUBSTACK, '_blank')}>Substack</p>
          <p className="footer-text-right" onClick={() => window.open(IG, '_blank')}>Instagram</p>
          <p className="footer-text-right" onClick={() => window.open(TIKTOK, '_blank')}>TikTok</p>
        </div>
      </div>
    </div>
  );
}