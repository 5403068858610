import React from 'react';
import './Contact.css';

export default function Contact() {
  return (
    <div className='contact'>
      <h1>Contact</h1>
      <p>To reach the Golfer Royale team please email support@chips.golf and a team member will be with you as soon as possible.</p>
    </div>
  );
}