import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Success.css';

import logo from '../logo.png';

export default function Success({ isMobile })    {    
    const navigate = useNavigate();
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');
        if (sessionId) {
            localStorage.setItem('checkoutCompleted', 'true');
            setTimeout(function() {
                navigate(`/buy`);
            }, 2000);
        }
    }, []);

    return (
        <div className={isMobile ? "success-container-mobile" : "success-container"}>
            <p></p>
            <div className={isMobile ? 'event-card-mobile' : 'event-card'}>
                <img src={logo} className={isMobile ? "success-logo-mobile" : "success-logo"} alt="logo" />
                <h2 className={'payment-successful'}>Payment Complete</h2>
                <h2 className={'payment-successful'}>Thank you!</h2>
            </div>
        </div>
    );
}