import React from 'react';
import './Rules.css';

export default function Rules() {
  return (
    <div className='rules'>
      <h1>Golfer Royale Rule Book v1.0</h1>
      <br></br>
      <h2>Table of Contents</h2>
      <p>1. General</p>
      <p>2. Real</p>
      <p>3. Sim</p>
      <p>4. VR</p>
      <br></br>
      <h3>1. General</h3>
      <h4>These are general rules that apply to all modes of play.</h4>
      <p>1.1 Double par is the max score per hole. The app won't let you log a higher score than this.</p>
      <p>1.2 Stat logging other than strokes is at the user's discretion and does not impact a player's tournament scores.</p>
      <br></br>
      <br></br>
      <h3>2. Real Golf</h3>
      <h4>Real golf is defined as a round of golf in the real physical world, the old fashioned way.</h4>
      <a href="https://www.usga.org/rules/rules-and-clarifications/rules-and-clarifications.html#!ruletype=fr&section=rule&rulenum=1" target="_blank">2.1 USGA Rules Govern All Play</a>
      <p>2.2 Follow all local course rules and etiquette.</p>
      <p>2.3 Players may lift, clean, and place ball within 1 club length anywhere on course except on the green.</p>
      <p>2.4 Players are permitted 1 breakfast ball off of the first tee. If a breakfast ball is taken then it is the official shot.</p>
      <p>2.5 Mulligans are discouraged.</p>
      <p>2.6 Gimmies are discouraged.</p>
      <br></br>
      <br></br>
      <h3>3. Sim Golf</h3>
      <h4>Sim golf is defined as a round of golf played on a golf simulator.</h4>
      <p>3.1 There are no standardized mode requirements for simulator golf. For the best user experience a player should try to play the same format whenever possible as consistency for an individual produces the best user experience.</p>
      <p>3.2 Mulligans are discouraged.</p>
      <i>We recommend Trackman simulators for the best simulator experience.</i>
      <br></br>
      <br></br>
      <br></br>
      <h3>4. VR Golf</h3>
      <h4>VR golf is defined as a round of golf played in a virtual reality environment using a virtual reality headset.</h4>
      <p>4.1 There are no standardized mode requirements for VR golf. For the best user experience a player should try to play by the same rules whenever possible as consistency for an individual produces the best user experience.</p>
      <p>4.2 Mulligans are discouraged.</p>
      <i>We recommend Golf+ on Oculus Quest for the best virtual reality experience.</i>
      <br></br>
      <br></br>
      <br></br>
      <p>
        {
          `Summary of rule publucations and amendments:`
        }
      </p>
      <ul>
        <li>March 11, 2024: First published.</li>
      </ul>
    </div>
  );
}