
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../context/GlobalState';

import { userSocket } from '../utils/api';

import './Header.css';

import logo from '../logo_no_text.png';
import name from '../name.png';

const Header = ({ isMobile }) => {
  const { user } = useGlobalState();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      console.log('disconnecting userSocket...')
      userSocket.disconnect();
    };
  }, []);

  const goHome = () => {
    console.log('going home');
    navigate(`/`);
  }

  const goToBuy = () => {
    console.log('going to buy');
    navigate(`/buy`);
  }

  return (
    <div className="header">
      <div className={isMobile ? "left-header-mobile" : "left-header"} onClick={() => goHome()}>
          <img src={logo} className={isMobile ? "header-logo-mobile" : "header-logo"} alt="logo" />
          <img src={name} className={isMobile ? "header-name-mobile" : "header-name"} alt="logo" />
      </div>
        <div className={isMobile ? "right-header-mobile" : "right-header"} onClick={() => goToBuy()}>
          <p className={isMobile ? "header-login-mobile" : "header-login"}>💰 Wallet 💰</p>
          {/* <p className="header-login">Sign Out</p> */}
        </div>
    </div>
  )
}


export default Header;