import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';
import { useGlobalState } from '../context/GlobalState';
import { userSocket, post } from '../utils/api';

import logo from '../logo_no_text.png';
import coin from '../gold_coin_no_bg.gif';

import './Buy.css';

const Buy = ({ isMobile }) => {
  const { user, signInUser, userSignOut, setUser, userIsSignedIn } = useGlobalState();

  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const prices = ['1', '10', '25', '100'];

  useEffect(() => {
    const userId = user ? user._id : null;
    const handleBalanceUpdate = async userBalancePayload => {
      const userClone = { ...user };
      userClone.balance = userBalancePayload.userBalance;
      setUser(userClone);
    };

    userSocket.on(`deliverUpdateBalance${userId}`, handleBalanceUpdate);

    return () => {
      userSocket.off(`deliverUpdateBalance${userId}`);
    };
  }, [userIsSignedIn])

  useEffect(() => {
    // check if user has stored token for auto login
    const fetchData = async () => {
      try {
        await checkForUserToken();
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const handleSignIn = async userPayload => {
      // store token in local storage
      localStorage.setItem('token', userPayload.token);
      localStorage.setItem('id', userPayload._id.toString());
      signInUser(userPayload);
      resetUserDetails();
      setIsLoading(false);
    };

    const handleSignOut = async success => {
      if (!success) {
        alert(`Error signing out`);
        return;
      }
      
      // remove items
      localStorage.removeItem('token');
      localStorage.removeItem('id');

      // clear user from global state
      userSignOut(null);
    };

    const handleFailure = (err) => {
      alert(`${err.message}`);
      setIsLoading(false);
    };

    const handleSignInAuto = async userPayload => {  
      // store token in local storage
      // store token in local storage
      localStorage.setItem('token', userPayload.token);
      localStorage.setItem('id', userPayload._id.toString());
      signInUser(userPayload);
      setIsLoading(false);
    };

    userSocket.on('connect', () => {
      console.log(`Device connected to users server: ${user}`);
    });

    userSocket.on('error-event', (err) => {
      alert(`${err.message}`);
      setIsLoading(false);
    });

    userSocket.on(`deliverUserSignIn`, handleSignIn);
    
    userSocket.on('signInFailure', handleFailure);

    userSocket.on(`deliverSignOutRes`, handleSignOut);

    userSocket.on(`deliverUserSignInAuto`, handleSignInAuto);
  }, []); 
  
  const checkForUserToken = async () => {
    const userId = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    if (!token || !userId) {
      setIsLoading(false);
      return;
    }

    const auth = {
      token,
      userId,
    };

    const payload = { 
      user: userId,
      token,
    };

    // hit endpoint to automatically sign the user in.
    userSocket.emit('signInAuto', payload, auth);
  };

  const handleSignOutClick = () => {
    signOut();
    userSignOut();
  };

  const signOut = async () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('id');

    const auth = {
        token,
        userId,
    };

    const payload = { 
        user: userId,
        token,
    };

    userSocket.emit('signOut', payload, auth);
}

  const resetUserDetails = () => {
    setUsername('');
    setPassword('');
  }

  const signIn = async () => {
    setIsLoading(true);

    if (username === '' || password === '') {
      alert('Username and password are required!');
      setIsLoading(false);
      return;
    }

    const payload = { username, password };
    userSocket.emit('userSignIn', payload);
  }

  const handleSignInClick = () => {
    signIn();
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignInClick();
    }
  };

  const handlePurchase = async (price) => {
    const userId = localStorage.getItem('id')
    const token = localStorage.getItem('token');

    if (!token || !userId) {
      return;
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    await post(`/payments/buy_chips/${userId}`, { price, userId }, config)
      .then(checkoutSession => {
        if (checkoutSession.statusCode === 400) {
          alert(`Error making buy: ${checkoutSession.raw.message}`);
          return;
        }
        window.location.assign(checkoutSession.url);
      })
      .catch(err => {
        setIsLoading(false);
        return;
      }); 
  };

  return (
    <div className={isMobile ? "home-container-mobile" : "home-container"}>
      {
        isLoading && !userIsSignedIn
        ?
        <LoadingSpinner />
        :
        <div>
          <div className={isMobile ? 'event-card-mobile' : 'event-card'}>
            {
              !user 
              ?
              <div className={'signin-form'}>
                <img src={logo} className={isMobile ? "home-logo-mobile" : "home-logo"} alt="logo" />
                <input className={'input'} type="text" value={username} onChange={handleUsernameChange} onKeyDown={handleKeyPress} placeholder="username" />
                <br />
                <input className={'input'} type="password" value={password} onChange={handlePasswordChange} onKeyDown={handleKeyPress} placeholder="password" />
                <br />
                <button onClick={handleSignInClick} className={'button'}>{'Sign In'}</button>
              </div>
              :
              <div className={'user-dashboard'}>
                <div className={'text-container'}>
                  <p className={'balance-text'}>{`Welcome ${user.username}!`}</p>
                  <img src={coin} className={isMobile ? "coin-logo-mobile" : "coin-logo"} alt="logo" />
                  <p className={'balance-text'}>Current balance: ${user.balance.toLocaleString()}</p>
                  <p className={'balance-text'}>{`Select Amount to Deposit ($USD)`}</p>
                </div>
                <div className={isMobile ? 'buy-chips-row-mobile' : 'buy-chips-row'}>
                    {
                      prices.map(price => {
                        return (
                          <button key={price} className={'buy-chips-button'} onClick={() => handlePurchase(price)}>
                              {`+ $${price}`}
                              {/* <img className={'coins-icon'} src={coinsIcon} alt="Coin Icon" />
                              {` ($${price})`} */}
                          </button>
                        )
                      })
                    }
                </div>
                <div onClick={() => handleSignOutClick()}>
                  <p className="signout-text">Sign Out</p>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  );
};

export default Buy;